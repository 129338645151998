import React from 'react'
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from "gatsby-plugin-image"
// import Articulo from './Articulo'

const Blog = () => {
	
	const data = useStaticQuery(graphql `
		query {
			allFile(filter: {sourceInstanceName: {eq: "blog-img"}}) {
			nodes {
				name
				childImageSharp {
				id
				gatsbyImageData(width: 672, breakpoints: 10)
				}
			}
			}
		}
	`);

	const nodeImages = data.allFile.nodes;

	return (
		<section className="container">
			<div className="row">

				{
					nodeImages.map( imagen => {
						
						const obtenerImagen = getImage(imagen);

						return (
							<div key={imagen.name} className="col-12 col-lg-4 mb-5">
								<article>
									<GatsbyImage
										image={obtenerImagen}
										alt={imagen.name}

									/>
									<div >
									<ul className="d-flex justify-content-start p-0 m-0">
										<li className="me-3">15 Septiembre, 2021</li>
										<li>Rosa Montcerdá</li>
									</ul>
									</div>
									<h2>Rem nobis quo nesciunt.</h2>
									<h5>Voluptatem nulla quos quis</h5>
									<p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. A molestiae dignissimos explicabo ipsam cum non dolor similique, tempora sapiente fugit, voluptatem nulla quos quis facere ipsum sed perspiciatis iste vel?</p>
									<button className="btn btn-primary">Leer</button>
								</article>
							</div>
						)
					})
				}

			</div>

		</section>
	)
}

export default Blog
