import * as React from "react"
import LayoutBase from "../layouts/LayoutBase"

import Slider from "../components/Slider/Slider"
import Servicios from "../components/Servicios/Servicios"
import Map from "../components/Map/Map"
import Blog from "../components/Blog/Blog"

const IndexPage = () => (
  <LayoutBase>

    <Slider />

    <section className="container-fluid bg-secondary text-white py-5">
        <div className="container">
            <h1 className="fs-2 montserrat fw-bold m-0">Centro de Desarrollo y Lenguaje</h1>
            <p className="h5 fw-lighter lh-base m-0">Hoy somos un despacho con capacidad para seis terapeutas y un espacio polivalente para charlas, grupos y formaciones pero nuestros orígenes son sencillos. Todo empezó en 2012 cuando tomé la decisión de abrir un gabinete especializado en TEA, Discapacidad Intelectual y Trastornos del Lenguaje. Desde entonces el equipo se ha ido ampliando así como nuestra respuesta terapéutica abarcando hoy en día todo el ciclo vital.</p>
        </div>
    </section>

    <section className="container my-5">
      <Servicios/>
    </section>
    
    <section className="container my-5">
      <Blog/>
      <Blog/>
    </section>

    <section className="mt-5">
      <Map />
    </section>
    
  </LayoutBase>
)

export default IndexPage
