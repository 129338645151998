import React from 'react'

const Servicios = () => {
	return (
		
			<div className="row my-6">

				<div className="col-12 col-xl-4">
					<div className="d-flex p-2">
						<div>
							<h2>Ut enim ad minim</h2>
								<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>
						</div>
					</div>
				</div>

				<div className="col-12 col-xl-4">
					<div className="d-flex p-2">
						<div className="">
							<h2>Consectetur adipiscing eli</h2>
							<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>
						</div>
					</div>
				</div>

				<div className="col-12 col-xl-4">
					<div className="d-flex p-2">
						<div>
							<h2>Voluptate velit esse cillum</h2>
							<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>
						</div>
					</div>
				</div>

			</div>
		
	)
}

export default Servicios
